body {
  height: 100vh;
  color: #fff;
  background-color: #222;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
}

canvas {
  border: 1px solid #fff;
}

/*# sourceMappingURL=index.0546c0b2.css.map */
