body {
    margin: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background-color: #222;
    color: #fff;
}
canvas {
    border: 1px solid #fff;
}
